<template>
  <div class="grid row">
    <div class="flex xs12">
      <va-card title="Редагування новини" class="fill-height">
        <div>
          <div class="row">
            <div class="flex xs12">
              <va-tabs v-model="tabValue" style="width: 100%; min-width: 250px; margin-bottom: 25px;">
                <va-tab
                  v-for="title in tabTitles"
                  :key="title"
                >
                  {{title}}
                </va-tab>
              </va-tabs>
              <div class="flex xs12" v-if="tabValue === 0">
                <va-input
                  label="Address"
                  v-model="address"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Tel"
                  v-model="telephone"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Email"
                  v-model="email"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Facebook"
                  v-model="socialFacebook"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-input
                  label="Country"
                  v-model="country"
                  :messages="['The recommended number of characters is 15']"
                />
                <va-button @click="submit()">Save</va-button>
              </div>
            </div>
          </div>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import * as axios from 'axios'
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/uk'
import Vue from 'vue'

export default {
  name: 'grid',
  components: {
    // ckeditor: CKEditor.component,
  },
  data () {
    return {
      address: '',
      telephone: '',
      email: '',
      socialFacebook: '',
      country: '',

      header: '',
      subheader: '',
      desc: '',
      link_name: '',
      link_href: '',
      text_contents_text: '',
      trailingText: '',
      stats_text: '',
      numList: null,
      quotes: null,
      stats: null,
      companyContents: null,

      img: [],
      imgId: '',
      imgExisting: '',
      minicardImg: [],
      minicardImgId: '',
      minicardImgExisting: '',
      label: '',
      alias: '',
      published_at: '08-05-2020 14:10:00',
      content: '',
      position: '',
      published: false,

      seoId: '',
      seoTitle: '',
      seoDescription: '',
      seoKeywords: '',
      seoText: '',
      seoImage: [],
      seoImageId: '',
      seoImageExisting: '',
      editor: ClassicEditor,
      editorConfig: {
        language: 'uk',
        simpleUpload: {
          uploadUrl: `${process.env.VUE_APP_AUTOSTRADA_API_URL}/uk/admin/editor/upload`,
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      },

      sign: '',

      errors: [],

      tabTitles: ['Main'],
      tabValue: 0,
    }
  },
  created () {
    if (!this.$attrs.id) {
      const today = new Date()
      this.published_at = today.toLocaleDateString('uk-UA') + ' ' + today.toLocaleTimeString('uk-UA')
      const generateRandomString = (length = 6) => Math.random().toString(20).substr(2, length)
      this.sign = generateRandomString(32)
    }
    this.fetch()
  },
  methods: {
    togglePublished (route, id) {
      axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/${route}/${id}`, {
        published: !!this.news.find((item) => item.id === id).published,
      })
        .then(response => {
          this.showToast('Success')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    uploadImage (attr, entityAttribute, param, file, model = 'backend\\modules\\news\\models\\News') {
      if (!file) {
        return
      }
      const formData = new FormData()
      formData.append('model_name', model)
      formData.append('attribute', attr)
      formData.append('entity_attribute', entityAttribute)
      formData.append(param, file)
      formData.append('file_id', '0')
      formData.append('multiple', '0')
      if (entityAttribute === 'SeoEntityImage') {
        formData.append('id', this.seoId)
      } else {
        if (this.$attrs.id) {
          formData.append('id', this.$attrs.id)
        } else {
          formData.append('sign', this.sign)
        }
      }

      axios.post(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          this.showToast('Successful upload')
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
    async deleteImage (id) {
      return axios.delete(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/upload/${id}`)
        .then(() => {
          this.showToast('Successfully deleted')
        })
        .catch(() => {
          this.showToast('Error')
        })
    },
    submit () {
      const data = {
        address: this.address,
        telephone: this.telephone,
        email: this.email,
        socialFacebook: this.socialFacebook,
        country: this.country,
      }

      axios.put(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/contacts`, data)
        .then(response => {
          this.$router.push({ name: 'ms-capital-page-contacts' })
          this.showToast('Success')
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          }
          console.log(error)
          this.showToast('Error')
        })
    },
    fetch () {
      axios.get(`${process.env.VUE_APP_MS_API_URL}/${this.locale}/admin/contacts`)
        .then(response => {
          console.log(response.data)
          this.address = response.data.address
          this.telephone = response.data.telephone
          this.email = response.data.email
          this.socialFacebook = response.data.socialFacebook
          this.country = response.data.country
        })
        .catch(error => {
          console.log(error)
          this.showToast('Error')
        })
    },
  },
  computed: {
    computedStyle () {
      return {
        backgroundColor: this.$themes.primary,
      }
    },
    locale () {
      return Vue.i18n.locale()
    },
  },
  watch: {
    locale: function (value) {
      this.fetch()
    },
  },
}
</script>

<style lang="scss">
.grid {
  &__container {
    min-height: 3rem;
    color: $white;
    border-radius: 0.5rem;
  }
}

.va-file-upload-gallery-item {
  flex-basis: unset;
  max-width: 400px;
  width: unset;
}
</style>
